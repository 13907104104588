import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const EDIT_COTACAO = 'EDIT_COTACAO'
export const DELETE_COTACAO = 'DELETE_COTACAO'
export const HISTORICO_COTACAO = 'HISTORICO_COTACAO'
export const ATUALIZAR_COTACAO = 'ATUALIZAR_COTACAO'
export const RESET_STATE = 'RESET_STATE'
export const NOVA_COTACAO = 'NOVA_COTACAO'

const getDefaultState = () => {
  return {
    ready: false,
    cotacoes: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    commit(SET_STATE, { ready: false })
    await dispatch(LOAD_BATCH, { data_inicial, data_final })
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH] ({ dispatch, commit }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    await api.listEntidade('cotacao/' + data_inicial + '/' + data_final).then(
      cotacoes => {
        commit(SET_STATE, { cotacoes })
      },
    )
  },
  [EDIT_COTACAO] ({ dispatch }, cotacao) {
    dispatch('form/cotacao/BOOTSTRAP_PROFILE', { id: cotacao.id }, { root: true })
  },

  [NOVA_COTACAO] ({ dispatch }) {
    dispatch('form/cotacao/BOOTSTRAP_PROFILE', { id: null }, { root: true })
  },

  [ATUALIZAR_COTACAO] ({ dispatch, state }, cotacao) {
    state.cotacoes.map(item => {
      if (item.id === cotacao.id) item.status = cotacao.status
    })
  },
  [HISTORICO_COTACAO] ({ dispatch }, cotacao) {
    dispatch('cotacaologs/BOOTSTRAP', { id: cotacao.id }, { root: true })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
