import profile from './profile'
import keys from './keys'
// import pedidos from './pedidos'
import loja from './loja'
import volumes from './volumes'
import qualidades from './qualidades'
import notificacoesProdutor from './notificacoesProdutor'
import pedidosProdutor from './pedidosProdutor'

const permissions = [
  {
    role: 'admin',
    access: false,
    redirect: 'admin',
  },
  {
    role: 'supervisor',
    access: false,
    redirect: 'supervisor',
  },
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'tecnico',
    access: false,
    redirect: 'tecnico',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

/* ACESSO PRODUTOR */
export default {
  name: 'produtor',
  path: '/produtor',
  redirect: '/produtor/dashboard',
  component: () => import('@/views/Index'),
  meta: { requiresAuth: true, permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/dashboard/DashboardProdutor'),
      meta: { permissions, title: 'Início' },
    },
    profile.router(permissions),
    pedidosProdutor.router(permissions),
    loja.router(permissions),
    volumes.router(permissions),
    qualidades.router(permissions),
    keys.router(permissions),
    notificacoesProdutor.router(permissions),
  ],

}
