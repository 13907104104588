import { v4 } from 'uuid'
export class Regra {
  constructor ({
    id,
    nome,
    tipo,
    produtos,
    condicoes,
    unidade,
    bonificacoes,
  } = {
    id: v4(),
    nome: '',
    tipo: '',
    unidade: null,
    produtos: [],
    condicoes: [
      {
        volume: '',
        desconto: '',
        parcelamento: '',
        quantidade: '',
        calculo: '',
        vlr_parcela: '',
        regra_tecnico: '0',
      },
    ],
    bonificacoes: [],
  }) {
    this.id = id
    this.nome = nome
    this.tipo = tipo
    this.unidade = unidade
    this.produtos = produtos.map(p => ({ ...p, regra: id }))
    this.condicoes = condicoes.map(c => ({ ...c }))
    this.bonificacoes = bonificacoes || []
  }

  addCondicao () {
    const condicao = {
      volume: '',
      desconto: '',
      parcelamento: '',
      quantidade: '',
      calculo: '',
      vlr_parcela: '',
      regra_tecnico: '0',
    }
    this.condicoes.push(condicao)
  }

  removeCondicao (index) {
    return this.condicoes.splice(index, 1)
  }
}

export function createRegra (data) {
  return Object.freeze(new Regra(data))
}
